<div matDialogTitle>
    <h2>{{title}}</h2>
</div>
<mat-dialog-content>
    <form [formGroup]="formEdit">
        <div class="row">
            <div class="col-6 col-md-3">
                <div class="box-photos mb-3">
                    <div class="col-12 box-photo" *ngFor="let photo of upload.previewPhotos; let i = index">
                        <div class="loader-img" [hidden]="!upload.isLoadingImg">
                            <mat-spinner strokeWidth="6"></mat-spinner>
                        </div>
                        <img [src]="photo.preview" *ngIf="upload.previewPhotos.length" [style.opacity]="upload.isLoadingImg ? '0.5' : '1'">
                        <img [src]="photo.img" *ngIf="photo.img">
                        <div class="delete-img">
                            <button mat-mini-fab color="warn" matTooltip="Excluir" matTooltipPosition="below" (click)="upload.deleteImg(i, formEdit)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 new-photo" *ngIf="!upload.previewPhotos.length">
                        <label matRipple for="fileInput">
                            <fa-icon [icon]="faPlusSquare"></fa-icon>
                            Nova foto
                        </label>
                        <input type="file" id="fileInput" name="imagem" (change)="upload.fileChangeEvent($event, formEdit)">
                    </div>
                </div>
            </div>

            <!-- File -->
            <div class="col-6 col-md-3">
                <div class="box-photos">
                    <div class="col-12 box-photo" *ngFor="let file of upload.previewFiles; let i = index">
                        <div class="loader-img" [hidden]="!upload.isLoadingFile">
                            <mat-spinner strokeWidth="6"></mat-spinner>
                        </div>
                        <label matRipple for="fileInputFile" *ngIf="file.file">
                            <fa-icon [icon]="faCheckCircle"></fa-icon>
                            Arquivo carregado
                        </label>
                        <div class="delete-img">
                            <button mat-mini-fab color="warn" matTooltip="Excluir" matTooltipPosition="below" (click)="upload.deleteFile(i, formEdit)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 new-photo" *ngIf="!upload.previewFiles.length">
                        <label matRipple for="fileInputFile">
                            <fa-icon [icon]="faPlusSquare"></fa-icon>
                            Novo arquivo (.pdf)
                        </label>
                        <input type="file" id="fileInputFile" name="file" (change)="upload.fileChangeEventFile($event, formEdit)">
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4 mb-4">
                <mat-divider></mat-divider>
            </div>

            <div class="col-12 col-md-9">
                <mat-form-field appearance="outline">
                    <mat-label>Título</mat-label>
                    <input matInput type="text" formControlName="title" required>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Cód.</mat-label>
                    <input matInput type="text" formControlName="cod" required>
                </mat-form-field>
            </div>
            
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Categoria</mat-label>
                    <mat-select formControlName="idCategory" (selectionChange)="getSubCategories(formEdit.get('idCategory').value)" required>
                        <mat-option *ngFor="let category of categories | orderBy:'title':'asc'" [value]="category._id">
                            {{category.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Sub-categoria</mat-label>
                    <mat-select formControlName="idSubCategory" required>
                        <mat-option *ngFor="let subcategory of subcategories | orderBy:'title':'asc'" [value]="subcategory._id">
                            {{subcategory.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Tags</mat-label>
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let tag of tagList" [selectable]="selectable" [removable]="removable" (removed)="removeChip(tag)">
                            {{tag}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="addChip($event)"
                            placeholder="Separe as tags por vírgula">
                    </mat-chip-list>
                </mat-form-field>
            </div>

            <div class="col-12 mb-3">
                <label>Descrição</label>
                <ckeditor [editor]="Editor" [config]="EditorConfig" formControlName="description"></ckeditor>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!formEdit.valid || isLoadingAction">Salvar</button>
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>