import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalAdminsAddComponent } from 'src/app/templates/modal-admins-add/modal-admins-add.component';
import { ModalAdminsEditComponent } from 'src/app/templates/modal-admins-edit/modal-admins-edit.component';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {

  public title: string = 'Admins'

  public user: any = {}
  public q = ''
  public admins: MatTableDataSource<any>
  public isLoading: boolean = false
  public isData: boolean = false
  public displayedColumns: string[] = ['email', 'createdAt', 'active', 'actions']

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort

  constructor(
    private auth: AuthService,
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if(this.auth.userVerify()) this.user = this.auth.userVerify()
    this.listAdmins()
  }

  private async listAdmins() {
    this.isLoading = true
    await this.api.getAPI('admins').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if (data.data.length) this.isData = true
        this.admins = new MatTableDataSource(data.data)
        this.admins.paginator = this.paginator
        this.admins.sort = this.sort
      }
    })
  }

  public search(q: string) {
    this.admins.filter = q.trim().toLowerCase()

    if (this.admins.paginator) {
      this.admins.paginator.firstPage()
    }
  }

  public add() {
    const dialogRef = this.dialog.open(ModalAdminsAddComponent, {
      width: '600px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listAdmins()
      }
    })
  }

  public edit(obj) {
    const dialogRef = this.dialog.open(ModalAdminsEditComponent, {
      width: '600px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listAdmins()
      }
    })
  }

  public async activate(obj) {
    if(this.user.master) {
      if(!obj.master) {
        let active = obj.active
        obj.active ? active = false : active = true
        
        const objData = {...obj, active}
        await this.api.putAPI(`admins/${obj._id}`, objData).subscribe((data: any) => {
          if(data.status) {
            this.toastr.success(data.msg, 'Sucesso!')
            this.listAdmins()
          } else {
            this.toastr.error(data.msg, 'Erro!')
          }
        })
      }
    }
  }

  public async delete(obj) {
    if(confirm('Você realmente deseja excluir este registro?')) {
      await this.api.deleteAPI(`admins/${obj._id}`).subscribe((data: any) => {
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listAdmins()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      }, (error: any) => {
        this.toastr.error(error.errors[0], 'Erro!')
      })
    }
  }

}
