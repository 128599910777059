import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  
  public filePath = ''
  public filePreview
  public isLoadingImg: boolean = false

  public previewPhotos = []
  public photos = []

  public previewFiles = []
  public files = []
  public isLoadingFile: boolean = false

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private toastr: ToastrService
  ) { }

  private upload(formName: FormGroup) {
    const photo: File = (<HTMLInputElement>document.getElementById('fileInput')).files[0]
    const formData: FormData = new FormData()
    formData.append('imagem', photo, photo.name)

    const options = {
      headers: new HttpHeaders({
        'Authorization': this.auth.userVerify().token
      })
    }
    this.isLoadingImg = true
    this.http.post(`${environment.baseUrl}/api/upload`, formData, options)
      .subscribe((data: any) => {
        this.isLoadingImg = false
        this.photos.push({img: data.data.filePath})
        formName.patchValue({
          img: this.photos[0].img
        })
        this.clearPreview(formName)
        this.toastr.success(data.msg, 'Sucesso!')
      }, (error: any) => {
        this.toastr.error(error.errors.errors[0], 'Erro!')
      })
  }

  public fileChangeEvent(ev: any, formName: FormGroup) {
    if(ev) { // se houver $event
      if(this.filePath) this.filePath = ''
      const preview: File = ev.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(preview)
      reader.onload = (_event) => {
        this.previewPhotos.push({file: ev.target.files[0], preview: reader.result})
        this.upload(formName)
      }
    } else { // passando parametro null para limpar
      if(document.getElementById('fileInput'))
        (<HTMLInputElement>document.getElementById('fileInput')).value = null
      this.filePreview = null
    }
  }

  private clearPreview(formName: FormGroup) {
    this.fileChangeEvent(null, formName)
  }

  public deleteImg(index: number, formName: FormGroup) {
    this.previewPhotos.splice(index, 1)
    this.photos.splice(index, 1)
    formName.patchValue({
      img: ''
    })
  }


  // File
  private uploadFile(formName: FormGroup) {
    const file: File = (<HTMLInputElement>document.getElementById('fileInputFile')).files[0]
    const formData: FormData = new FormData()
    formData.append('file', file, file.name)

    const options = {
      headers: new HttpHeaders({
        'Authorization': this.auth.userVerify().token
      })
    }
    this.isLoadingFile = true
    this.http.post(`${environment.baseUrl}/api/upload-file`, formData, options)
      .subscribe((data: any) => {
        this.isLoadingFile = false
        this.files.push({file: data.data.filePath})
        formName.patchValue({
          file: this.files[0].file
        })
        this.clearPreviewFile(formName)
        this.toastr.success(data.msg, 'Sucesso!')
      }, (error: any) => {
        this.toastr.error(error.errors.errors[0], 'Erro!')
      })
  }

  public fileChangeEventFile(ev: any, formName: FormGroup) {
    if(ev) { // se houver $event
      if(this.filePath) this.filePath = ''
      const preview: File = ev.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(preview)
      reader.onload = (_event) => {
        this.previewFiles.push({file: ev.target.files[0], preview: reader.result})
        this.uploadFile(formName)
      }
    } else { // passando parametro null para limpar
      if(document.getElementById('fileInputFile'))
        (<HTMLInputElement>document.getElementById('fileInputFile')).value = null
      this.filePreview = null
    }
  }

  private clearPreviewFile(formName: FormGroup) {
    this.fileChangeEventFile(null, formName)
  }

  public deleteFile(index, formName: FormGroup) {
    this.previewFiles.splice(index, 1)
    this.files.splice(index, 1)
    formName.patchValue({
      file: ''
    })
  }
}
