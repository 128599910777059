import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalUsersAddComponent } from 'src/app/templates/modal-users-add/modal-users-add.component';
import { ModalUsersEditComponent } from 'src/app/templates/modal-users-edit/modal-users-edit.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public title: string = 'Usuários'

  public q = ''
  public users: MatTableDataSource<any>
  public isLoading: boolean = false
  public isData: boolean = false
  public displayedColumns: string[] = ['name', 'email', 'phone', 'address', 'active', 'actions']

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listUsers()
  }

  private async listUsers() {
    this.isLoading = true
    await this.api.getOAPI('users').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if (data.data.length) this.isData = true
        this.users = new MatTableDataSource(data.data)
        this.users.paginator = this.paginator
        this.users.sort = this.sort
      }
    })
  }

  public userAddress = (data) => {
    if(data.address?.street && data.address?.number && data.address?.neighborhood && data.address?.city && data.address?.state) {
      return `${data.address?.street}, ${data.address?.number} - ${data.address?.complement} | ${data.address?.neighborhood} - ${data.address?.city} / ${data.address?.state}`
    } else {
      return 'Não informado'
    }
  }

  public search(q: string) {
    this.users.filter = q.trim().toLowerCase()

    if (this.users.paginator) {
      this.users.paginator.firstPage()
    }
  }

  public add() {
    const dialogRef = this.dialog.open(ModalUsersAddComponent, {
      width: '900px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listUsers()
      }
    })
  }

  public edit(obj) {
    const dialogRef = this.dialog.open(ModalUsersEditComponent, {
      width: '900px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listUsers()
      }
    })
  }

  public async activate(obj) {
    let active = obj.active
    obj.active ? active = false : active = true
    
    const objData = {...obj, active}
    await this.api.putAPI(`users/${obj._id}`, objData).subscribe((data: any) => {
      if(data.status) {
        this.toastr.success(data.msg, 'Sucesso!')
        this.listUsers()
      } else {
        this.toastr.error(data.msg, 'Erro!')
      }
    })
  }

  public async delete(obj) {
    if(confirm('Você realmente deseja excluir este registro?')) {
      await this.api.deleteAPI(`users/${obj._id}`).subscribe((data: any) => {
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listUsers()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      }, (error: any) => {
        this.toastr.error(error.errors[0], 'Erro!')
      })
    }
  }

}
