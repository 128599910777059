import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ModalResetPasswordComponent } from 'src/app/templates/modal-reset-password/modal-reset-password.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup

  public title: string = 'Forentec Dashboard'

  public isLoading: boolean = false

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
    this.formLogin = this.fb.group({
      email: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required])
    })
  }

  ngOnInit() {
    if(this.auth.userVerify()) {
      this.router.navigate(['/admins'])
    }
  }

  public async login() {
    this.isLoading = true
    await this.api.postOAPI('login-admin', this.formLogin.value).subscribe((data: any) => {
      this.isLoading = false
      if(data.token) {
        this.auth.setUser(data)
        this.toastr.success('Seja bem-vindo.', 'Sucesso!')
        this.router.navigate(['/admins'])
      } else {
        this.toastr.error(data.errors, 'Erro!')
      }
    }, (error: any) => {
      this.isLoading = false
      this.toastr.error(error.error.errors, 'Erro!')
      console.log('login', error.error.errors)
    })
  }

  public modalResetPassword(e) {
    e.preventDefault()
    this.dialog.open(ModalResetPasswordComponent, {
      width: '500px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: false
    })
  }

}
