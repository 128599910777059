import { Injectable, EventEmitter, Output } from '@angular/core';
import { Subject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Admin } from '../models/admin';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token = 'codeartdashboard'
  public admin: Admin = <Admin>{}

  constructor(
    private http: HttpClient,
    private router: Router,
    private util: UtilsService
  ) { }

  @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>()

  setUser(data) {
    this.util.set(this.token, data)
    this.fireIsLoggedIn.emit(data)
  }

  // getUser = () => this.fireIsLoggedIn

  public userVerify = () => this.util.get(this.token)

  public logout() {
    this.util.del(this.token)
    this.fireIsLoggedIn.emit({...this.admin})
    this.router.navigate(['/login'])
  }
}
