import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';
import { UploadService } from 'src/app/services/upload.service';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import * as ClassicEditor from 'src/ckeditor';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-contents-edit',
  templateUrl: './modal-contents-edit.component.html',
  styleUrls: ['./modal-contents-edit.component.scss']
})
export class ModalContentsEditComponent implements OnInit {
  formEdit: FormGroup

  public title: string = 'Editar'
  
  public faPlusSquare = faPlusSquare
  public faInfoCircle = faInfoCircle
  
  public isLoadingAction: boolean = false

  public sections = ['Banner Topo', 'Banner Rodapé', 'Sobre']

  public Editor = ClassicEditor
  public EditorConfig = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote', 'undo', 'redo']
  }

  constructor(
    private dialogRef: MatDialogRef<ModalContentsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    public util: UtilsService,
    public upload: UploadService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
  }

  private prepareForm() {
    this.formEdit = this.fb.group({
      section: this.fb.control(this.data.section, [Validators.required]),
      content: this.fb.control(this.data.content),
      img: this.fb.control(this.data.img),
      url: this.fb.control(this.data.url),
      active: this.fb.control(this.data.active)
    })
    this.upload.photos = []
    this.upload.previewPhotos = []
    if(this.data.img) {
      this.upload.previewPhotos = [{img: this.data.img}]
    }
  }

  public async save() {
    if(!this.formEdit.valid) {
      const arr = this.util.getFormValidationErrors(this.formEdit)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.toastr.error(`Preencha todos os campos obrigatórios: ${newArr}`, 'Erro!')
    } else {
      this.isLoadingAction = true
      await this.api.putAPI(`contents/${this.data._id}`, this.formEdit.value).subscribe((data: any) => {
        this.isLoadingAction = false
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.close(true)
        }
      }, (err: any) => {
        this.isLoadingAction = false
        this.toastr.error(err.error.errors, 'Erro!')
      })
    }
  }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

}
