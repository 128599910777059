import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  // Open API
  public getOAPI = (url) => this.http.get(`${environment.baseUrl}/oapi/${url}`)
  public postOAPI = (url, dados) => this.http.post(`${environment.baseUrl}/oapi/${url}`, dados)
  public putOAPI = (url, dados) => this.http.put(`${environment.baseUrl}/oapi/${url}`, dados)

  // Private API
  public getAPI(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.userVerify().token
      })
    }
    return this.http.get(`${environment.baseUrl}/api/${url}`, httpOptions)
  }

  public postAPI(url, dados) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.userVerify().token
      })
    }
    return this.http.post(`${environment.baseUrl}/api/${url}`, dados, httpOptions)
  }

  public putAPI(url, dados) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.userVerify().token
      })
    }
    return this.http.put(`${environment.baseUrl}/api/${url}`, dados, httpOptions)
  }

  public deleteAPI(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.userVerify().token
      })
    }
    return this.http.delete(`${environment.baseUrl}/api/${url}`, httpOptions)
  }
}
