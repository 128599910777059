<div matDialogTitle>
    <h2>{{title}}</h2>
</div>
<mat-dialog-content (keydown.enter)="save()">
    <form [formGroup]="formEdit">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Categoria</mat-label>
                    <mat-select formControlName="idCategory" (selectionChange)="getCategoryName()" required>
                        <mat-option *ngFor="let category of categories | orderBy:'title':'asc'" [value]="category._id">
                            {{category.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Sub-categoria</mat-label>
                    <mat-select formControlName="idSubCategory" (selectionChange)="getSubCategoryName()" required>
                        <mat-option *ngFor="let subcategory of subcategories | orderBy:'title':'asc'" [value]="subcategory._id">
                            {{subcategory.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Sub-sub-categoria</mat-label>
                    <input matInput type="text" formControlName="title" placeholder="Título da sub-sub-categoria" required>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!formEdit.valid || isLoadingAction">Salvar</button>
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>