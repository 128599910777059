<div matDialogTitle>
    <h2>{{title}}</h2>
</div>
<mat-dialog-content>
    <div class="row">
        <div class="col-12 col-md-4 mb-4">
            <div class="brick">
                <h4>Nome</h4>
                <p>{{data.client.name}}</p>
            </div>
        </div>
        <div class="col-12 col-md-4 mb-4">
            <div class="brick">
                <h4>E-mail</h4>
                <p>{{data.client.email}}</p>
            </div>
        </div>
        <div class="col-12 col-md-4 mb-4">
            <div class="brick">
                <h4>Telefone</h4>
                <p>{{data.client.phone ? data.client.phone : 'Não informado'}}</p>
            </div>
        </div>
        <div class="col-12">
            <div class="brick">
                <h4>Endereço</h4>
                <p>{{userAddress(data.client)}}</p>
            </div>
        </div>
    </div>
    <mat-divider class="mt-4 mb-4"></mat-divider>
    <div class="table-responsive">
        <table class="table table-hovered table-striped table-bordered">
            <thead>
                <tr>
                    <th>Produto</th>
                    <th>Cód.</th>
                    <th>Qtd.</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data.items">
                    <td>{{item.title}}</td>
                    <td>{{item.cod}}</td>
                    <td>{{item.count}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>