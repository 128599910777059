import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-subcategories-edit',
  templateUrl: './modal-subcategories-edit.component.html',
  styleUrls: ['./modal-subcategories-edit.component.scss']
})
export class ModalSubcategoriesEditComponent implements OnInit {
  formEdit: FormGroup

  public title: string = 'Editar'
  
  public isLoadingAction: boolean = false

  public categories = []

  constructor(
    private dialogRef: MatDialogRef<ModalSubcategoriesEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private util: UtilsService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
    this.getCategories()
  }

  private prepareForm() {
    this.formEdit = this.fb.group({
      title: this.fb.control(this.data.title, [Validators.required]),
      idCategory: this.fb.control(this.data.idCategory, [Validators.required]),
      category: this.fb.control(this.data.category),
      active: this.fb.control(this.data.active)
    })
  }

  public save() {
    if(!this.formEdit.valid) {
      const arr = this.util.getFormValidationErrors(this.formEdit)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.toastr.error(`Preencha todos os campos obrigatórios: ${newArr}`, 'Erro!')
    } else {
      this.isLoadingAction = true
      this.api.putAPI(`subcategories/${this.data._id}`, this.formEdit.value).subscribe((data: any) => {
        this.isLoadingAction = false;
        if (data.status) {
          this.toastr.success(data.msg, 'Sucesso!');
          this.close(true);
        }
      }, (err: any) => {
        this.isLoadingAction = false;
        this.toastr.error(err.error.errors, 'Erro!');
      })
    }
  }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

  public getCategories() {
    this.api.getOAPI('categories').subscribe((data: any) => {
      if(data.status) {
        this.categories = data.data
      }
    })
  }

  public getCategoryName() {
    const category = this.categories.filter(val => val._id === this.formEdit.value.idCategory)[0].title
    this.formEdit.patchValue({ category })
  }

}
