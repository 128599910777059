<div class="sidebar-backdrop" [class.backdrop-active]="!sidebarCollapsed" (click)="sidebarCollapse()"></div>
<nav class="navbar navbar-expand-md fixed-top" [class.sidebar-opened]="!sidebarCollapsed">
    <!-- <a href="" [routerLink]="" class="btn-collapse" (click)="sidebarCollapse()"><fa-icon [icon]="faBars"></fa-icon></a> -->
    <button class="btn-collapse" (click)="sidebarCollapse()"><fa-icon [icon]="faBars"></fa-icon></button>
    <p class="navbar-brand">Forentec Dashboard</p>
    <button class="hamburger hamburger--collapse" type="button"
            [ngClass]="{'is-active': !sidebarCollapsed}"
            (click)="sidebarCollapse()"
            aria-expanded="false" aria-label="Toggle navigation">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
</nav>

<div id="sidebar" [class.sidebar-opened]="!sidebarCollapsed">
    <ul class="list-unstyled">
        <li class="nav-item" [routerLinkActive]="page.route ? 'active' : ''" *ngFor="let page of pages">
            <a class="nav-link" [routerLink]="page.route" (click)="dropdown($event, page)">
                <div class="box-icon"><fa-icon [icon]="page.icon"></fa-icon></div>
                <div class="box-title">{{page.text}}</div>
                <div class="box-arrow" *ngIf="page.sublinks">
                    <fa-icon [icon]="dropdownOpened ? faChevronDown : faChevronLeft"></fa-icon>
                </div>
            </a>
            <div class="box-sublinks" *ngIf="page.sublinks" [style.display]="dropdownOpened ? 'block' : 'none'">
                <ul class="list-unstyled">
                    <li class="nav-item" routerLinkActive="active" *ngFor="let link of page.sublinks">
                        <a class="nav-link" [routerLink]="link.route">
                            <div class="box-icon"><fa-icon [icon]="link.icon"></fa-icon></div>
                            <div class="box-title">{{link.text}}</div>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <mat-divider></mat-divider>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" (click)="toggleDarkMode()">
                <div class="box-icon"><fa-icon [icon]="darkMode.icon"></fa-icon></div>
                <div class="box-title">{{darkMode.text}}</div>
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" (click)="logout()">
                <div class="box-icon"><fa-icon [icon]="faDoorOpen"></fa-icon></div>
                <div class="box-title">Sair</div>
            </a>
        </li>
    </ul>
</div>