import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { faBars, faUserShield, faUsers, faDoorOpen, faMoon, faSun, faFileSignature, faCubes, faCube, faFlask, faShoppingBasket, faNewspaper, faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { UtilsService } from 'src/app/services/utils.service';
import { Pages } from 'src/app/models/pages';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public faBars = faBars
  public faDoorOpen = faDoorOpen
  public faChevronLeft = faChevronLeft
  public faChevronDown = faChevronDown

  public dropdownOpened: boolean = false

  public pages: Pages[] = [
    {
      route: '/admins',
      icon: faUserShield,
      text: 'Admins'
    },
    {
      route: '/usuarios',
      icon: faUsers,
      text: 'Usuários'
    },
    {
      route: '/conteudos',
      icon: faFileSignature,
      text: 'Conteúdos'
    },
    {
      icon: faCubes,
      text: 'Gerenciar categorias',
      sublinks: [
        {
          route: '/categorias',
          icon: faCube,
          text: 'Categorias'
        },
        {
          route: '/subcategorias',
          icon: faCube,
          text: 'Sub-categorias'
        }
      ]
    },
    {
      route: '/produtos',
      icon: faFlask,
      text: 'Produtos'
    },
    {
      route: '/pedidos',
      icon: faShoppingBasket,
      text: 'Pedidos'
    },
    {
      route: '/newsletter',
      icon: faNewspaper,
      text: 'Newsletter'
    }
  ]

  public darkMode = {
    isDark: false,
    icon: faMoon,
    text: 'Modo escuro'
  }

  public sidebarCollapsed: boolean
  
  public mobileScreen = $(window).width() < 992

  constructor(
    private auth: AuthService,
    public router: Router,
    private util: UtilsService
  ) {
    this.router.events.subscribe(ev => {
      if(ev instanceof NavigationEnd) {
        if(this.mobileScreen) {
          this.sidebarCollapsed = true
        }
        this.keepDropdownOpened(this.router.url)
      }
    })
  }

  ngOnInit(): void {
    if(this.mobileScreen) {
      this.sidebarCollapsed = true
    } else {
      this.sidebarCollapsed = false
    }

    if(this.util.get('dark-mode')) {
      this.darkMode = this.util.get('dark-mode')
      
      if(!this.darkMode.isDark) {
        document.getElementsByTagName('body')[0].classList.remove('dark-theme')
      } else {
        document.getElementsByTagName('body')[0].classList.add('dark-theme')
      }
    }
  }

  public sidebarCollapse() {
    this.sidebarCollapsed = !this.sidebarCollapsed
    this.util.collapseSidebar(this.sidebarCollapsed)
  }

  public toggleDarkMode() {
    if(this.darkMode.isDark) {
      this.darkMode.isDark = false
      this.darkMode.icon = faMoon
      this.darkMode.text = 'Modo escuro'
      document.getElementsByTagName('body')[0].classList.remove('dark-theme')
    } else {
      this.darkMode.isDark = true
      this.darkMode.icon = faSun
      this.darkMode.text = 'Modo claro'
      document.getElementsByTagName('body')[0].classList.add('dark-theme')
    }
    this.util.set('dark-mode', this.darkMode)
  }

  logout() {
    if(this.auth.userVerify()) {
      this.auth.logout()
    }
  }

  public dropdown(e, obj) {
    if(obj.route) return
    e.preventDefault()
    this.dropdownOpened = !this.dropdownOpened
  }

  public keepDropdownOpened(route: string) {
    this.pages.forEach(el => {
      if(el.sublinks) {
        const routes = el.sublinks.map(val => val.route)
        routes.includes(route) ? this.dropdownOpened = true : this.dropdownOpened = false
      }
    })
  }

}
