<div matDialogTitle>
    <h2>{{title}}</h2>
</div>
<mat-dialog-content>
    <ng-container *ngIf="data.img">
        <img [src]="data.img" class="img-fluid">
    </ng-container>
    <ng-container *ngIf="data.content">
        <p [innerHTML]="data.content"></p>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>