<div matDialogTitle>
    <h2>{{title}}</h2>
</div>
<mat-dialog-content (keydown.enter)="save()">
    <form [formGroup]="formAdd">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="name" required>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input matInput type="email" formControlName="email" required>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input matInput type="text" formControlName="phone" mask="(00) 0000-0000 || (00) 0 0000-0000" [dropSpecialCharacters]="false">
                </mat-form-field>
            </div>
            <ng-container formGroupName="address">
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>CEP</mat-label>
                        <input matInput type="text" formControlName="cep" mask="00000-000" [dropSpecialCharacters]="false" (change)="getAddressByCep(formAdd.value.address.cep)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-9">
                    <mat-form-field appearance="outline">
                        <mat-label>Rua</mat-label>
                        <input matInput type="text" formControlName="street">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Número</mat-label>
                        <input matInput type="number" formControlName="number">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Complemento</mat-label>
                        <input matInput type="text" formControlName="complement">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-7">
                    <mat-form-field appearance="outline">
                        <mat-label>Bairro</mat-label>
                        <input matInput type="text" formControlName="neighborhood">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-9">
                    <mat-form-field appearance="outline">
                        <mat-label>Cidade</mat-label>
                        <input matInput type="text" formControlName="city">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="state">
                            <mat-option *ngFor="let state of states | orderBy:'sigla':'asc'" [value]="state.sigla">
                                {{state.sigla}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" formControlName="password" required>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Confirmar senha</mat-label>
                    <input matInput type="password" formControlName="confirmPassword" required>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!formAdd.valid || isLoadingAction">Salvar</button>
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>