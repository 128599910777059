<div matDialogTitle>
    <h2>{{title}}</h2>
</div>
<mat-dialog-content>
    <form [formGroup]="formAdd">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Seção</mat-label>
                    <mat-select formControlName="section" required>
                        <mat-option *ngFor="let section of sections" [value]="section">
                            {{section}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12" [hidden]="!formAdd.value.section || formAdd.value.section === 'Sobre'">
                <mat-form-field appearance="outline">
                    <mat-label>Link</mat-label>
                    <input matInput type="text" formControlName="url" placeholder="Ex.: https://codeart.com.br/">
                </mat-form-field>
            </div>
            <div class="col-12" [hidden]="!formAdd.value.section">
                <ng-container *ngIf="formAdd.value.section === 'Sobre'">
                    <label>Conteúdo</label>
                    <ckeditor [editor]="Editor" [config]="EditorConfig" formControlName="content"></ckeditor>
                </ng-container>
                <ng-container *ngIf="formAdd.value.section !== 'Sobre'">
                    <div class="alert alert-info">
                        <fa-icon [icon]="faInfoCircle"></fa-icon> Recomenda-se uma imagem com largura máxima de 1920px e altura máxima de 400px
                    </div>
                    <div class="box-photos">
                        <div class="col-6 col-md-2 box-photo" *ngFor="let photo of upload.previewPhotos; let i = index">
                            <div class="loader-img" [hidden]="!upload.isLoadingImg">
                                <mat-spinner strokeWidth="6"></mat-spinner>
                            </div>
                            <img [src]="photo.preview" *ngIf="upload.previewPhotos.length" [style.opacity]="upload.isLoadingImg ? '0.5' : '1'">
                            <div class="delete-img">
                                <button mat-mini-fab color="warn" matTooltip="Excluir" matTooltipPosition="below" (click)="upload.deleteImg(i, formAdd)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="col-6 col-md-2 new-photo" *ngIf="!upload.previewPhotos.length">
                            <label matRipple for="fileInput">
                                <fa-icon [icon]="faPlusSquare"></fa-icon>
                                Nova foto
                            </label>
                            <input type="file" id="fileInput" name="imagem" (change)="upload.fileChangeEvent($event, formAdd)">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!formAdd.valid || isLoadingAction">Salvar</button>
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>