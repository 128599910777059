<section id="contents" class="content">
    <div class="container-fluid">
        <div class="page-header">
            <div class="title">
                {{title}}
                <div class="line end"></div>
            </div>
        </div>

        <div class="loader-container" [hidden]="!isLoading">
            <mat-spinner strokeWidth="6"></mat-spinner>
        </div>

        <div class="alert alert-info no-data" [hidden]="isLoading || isData">
            Nenhum registro foi encontrado
        </div>

        <div [hidden]="isLoading || !isData">
            <mat-form-field>
                <input matInput type="text" placeholder="Pesquisar" (keyup)="search($event.target.value)">
            </mat-form-field>
    
            <div class="table-responsive mat-elevation-z2">
                <table mat-table [dataSource]="newsletter" matSort matSortActive="createdAt" matSortDirection="desc">

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                        <td mat-cell *matCellDef="let item">{{item.name}}</td>
                    </ng-container>
                    
                    <!-- E-mail Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
                        <td mat-cell *matCellDef="let item">{{item.email}}</td>
                    </ng-container>
                    
                    <!-- Created at Column -->
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Criado</th>
                        <td mat-cell *matCellDef="let item">{{item.createdAt | date:'dd/MM/yyyy'}}</td>
                    </ng-container>
    
                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Ações</th>
                        <td mat-cell *matCellDef="let item">
                            <button mat-flat-button color="warn" (click)="delete(item)">Excluir</button>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    
    <button mat-fab color="accent" class="mat-btn-float" matTooltip="Exportar (.csv)" (click)="export()">
        <fa-icon [icon]="faFileCsv"></fa-icon>
    </button>
</section>