import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalCategoriesAddComponent } from 'src/app/templates/modal-categories-add/modal-categories-add.component';
import { ModalCategoriesEditComponent } from 'src/app/templates/modal-categories-edit/modal-categories-edit.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public title: string = 'Categorias'

  public q = ''
  public categories: MatTableDataSource<any>
  public isLoading: boolean = false
  public isData: boolean = false
  public displayedColumns: string[] = ['title', 'createdAt', 'active', 'actions']

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listCategories()
  }

  private async listCategories() {
    this.isLoading = true
    await this.api.getOAPI('categories').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if (data.data.length) this.isData = true
        this.categories = new MatTableDataSource(data.data)
        this.categories.paginator = this.paginator
        this.categories.sort = this.sort
      }
    })
  }

  public search(q: string) {
    this.categories.filter = q.trim().toLowerCase()

    if (this.categories.paginator) {
      this.categories.paginator.firstPage()
    }
  }

  public add() {
    const dialogRef = this.dialog.open(ModalCategoriesAddComponent, {
      width: '600px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listCategories()
      }
    })
  }

  public edit(obj) {
    const dialogRef = this.dialog.open(ModalCategoriesEditComponent, {
      width: '600px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listCategories()
      }
    })
  }

  public async activate(obj) {
    let active = obj.active
    obj.active ? active = false : active = true
    
    const objData = {...obj, active}
    await this.api.putAPI(`categories/${obj._id}`, objData).subscribe((data: any) => {
      if(data.status) {
        this.toastr.success(data.msg, 'Sucesso!')
        this.listCategories()
      } else {
        this.toastr.error(data.msg, 'Erro!')
      }
    })
  }

  public async delete(obj) {
    if(confirm('Você realmente deseja excluir este registro?')) {
      await this.api.deleteAPI(`categories/${obj._id}`).subscribe((data: any) => {
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listCategories()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      }, (error: any) => {
        this.toastr.error(error.errors[0], 'Erro!')
      })
    }
  }

}
