import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';
import { NgxViacepModule } from '@brunoc/ngx-viacep';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr)
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginationCtrl } from './models/mat-pagination-ctrl';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ModalResetPasswordComponent } from './templates/modal-reset-password/modal-reset-password.component';
import { HeaderComponent } from './templates/header/header.component';
import { ContentsComponent } from './pages/contents/contents.component';
import { UsersComponent } from './pages/users/users.component';
import { ProductsComponent } from './pages/products/products.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { SubcategoriesComponent } from './pages/subcategories/subcategories.component';
import { SubsubcategoriesComponent } from './pages/subsubcategories/subsubcategories.component';
import { SafePipe } from './pipes/safe.pipe';
import { ModalAdminsAddComponent } from './templates/modal-admins-add/modal-admins-add.component';
import { ModalAdminsEditComponent } from './templates/modal-admins-edit/modal-admins-edit.component';
import { ModalUsersAddComponent } from './templates/modal-users-add/modal-users-add.component';
import { ModalUsersEditComponent } from './templates/modal-users-edit/modal-users-edit.component';
import { ModalContentsAddComponent } from './templates/modal-contents-add/modal-contents-add.component';
import { ModalContentsEditComponent } from './templates/modal-contents-edit/modal-contents-edit.component';
import { ModalContentsImgComponent } from './templates/modal-contents-img/modal-contents-img.component';
import { ModalCategoriesAddComponent } from './templates/modal-categories-add/modal-categories-add.component';
import { ModalCategoriesEditComponent } from './templates/modal-categories-edit/modal-categories-edit.component';
import { ModalSubcategoriesAddComponent } from './templates/modal-subcategories-add/modal-subcategories-add.component';
import { ModalSubcategoriesEditComponent } from './templates/modal-subcategories-edit/modal-subcategories-edit.component';
import { ModalSubsubcategoriesAddComponent } from './templates/modal-subsubcategories-add/modal-subsubcategories-add.component';
import { ModalSubsubcategoriesEditComponent } from './templates/modal-subsubcategories-edit/modal-subsubcategories-edit.component';
import { ModalProductsAddComponent } from './templates/modal-products-add/modal-products-add.component';
import { ModalProductsEditComponent } from './templates/modal-products-edit/modal-products-edit.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { ModalRequestsDetailsComponent } from './templates/modal-requests-details/modal-requests-details.component';

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    OrderByPipe,
    LoginComponent,
    ResetPasswordComponent,
    ModalResetPasswordComponent,
    HeaderComponent,
    ContentsComponent,
    UsersComponent,
    ProductsComponent,
    AdminsComponent,
    CategoriesComponent,
    SubcategoriesComponent,
    SubsubcategoriesComponent,
    SafePipe,
    ModalAdminsAddComponent,
    ModalAdminsEditComponent,
    ModalUsersAddComponent,
    ModalUsersEditComponent,
    ModalContentsAddComponent,
    ModalContentsEditComponent,
    ModalContentsImgComponent,
    ModalCategoriesAddComponent,
    ModalCategoriesEditComponent,
    ModalSubcategoriesAddComponent,
    ModalSubcategoriesEditComponent,
    ModalSubsubcategoriesAddComponent,
    ModalSubsubcategoriesEditComponent,
    ModalProductsAddComponent,
    ModalProductsEditComponent,
    RequestsComponent,
    NewsletterComponent,
    ModalRequestsDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
    CKEditorModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxViacepModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MatPaginatorIntl, useClass: MatPaginationCtrl }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalAdminsAddComponent,
    ModalAdminsEditComponent,
    ModalUsersAddComponent,
    ModalUsersEditComponent,
    ModalContentsAddComponent,
    ModalContentsEditComponent,
    ModalContentsImgComponent,
    ModalCategoriesAddComponent,
    ModalCategoriesEditComponent,
    ModalSubcategoriesAddComponent,
    ModalSubcategoriesEditComponent,
    ModalSubsubcategoriesAddComponent,
    ModalSubsubcategoriesEditComponent,
    ModalProductsAddComponent,
    ModalProductsEditComponent,
    ModalRequestsDetailsComponent
  ]
})
export class AppModule { }
