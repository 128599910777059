import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalProductsAddComponent } from 'src/app/templates/modal-products-add/modal-products-add.component';
import { ModalProductsEditComponent } from 'src/app/templates/modal-products-edit/modal-products-edit.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  public title: string = 'Produtos'

  public q = ''
  public products: MatTableDataSource<any>
  public isLoading: boolean = false
  public isData: boolean = false
  public displayedColumns: string[] = ['title', 'cod', 'category', 'subCategory', 'createdAt', 'detach', 'active', 'actions']
  
  public categories = []
  public subCategories = []
  public subSubCategories = []

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listProducts()
    this.getCategories()
    this.getSubCategories()
    this.getSubSubCategories()
  }

  private async listProducts() {
    this.isLoading = true
    await this.api.getOAPI('products').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if (data.data.length) this.isData = true
        this.products = new MatTableDataSource(data.data)
        this.products.paginator = this.paginator
        this.products.sort = this.sort
      }
    })
  }

  public search(q: string) {
    this.products.filter = q.trim().toLowerCase()

    if (this.products.paginator) {
      this.products.paginator.firstPage()
    }
  }

  private getCategories() {
    this.api.getOAPI('categories').subscribe((data: any) => {
      this.categories = data.data
    })
  }

  private getSubCategories() {
    this.api.getOAPI('subcategories').subscribe((data: any) => {
      this.subCategories = data.data
    })
  }

  private getSubSubCategories() {
    this.api.getOAPI('subsubcategories').subscribe((data: any) => {
      this.subSubCategories = data.data
    })
  }

  public add() {
    const dialogRef = this.dialog.open(ModalProductsAddComponent, {
      width: '900px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listProducts()
      }
    })
  }

  public edit(obj) {
    const dialogRef = this.dialog.open(ModalProductsEditComponent, {
      width: '900px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listProducts()
      }
    })
  }

  public draft(ev, obj) {
    const objData = { ...obj, detach: ev.checked }

    this.api.putAPI(`products/${obj._id}`, objData)
      .subscribe((data: any) => {
        if (data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listProducts()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      })
  }

  public activate(ev, obj) {
    const objData = { ...obj, active: ev.checked }
    
    this.api.putAPI(`products/${obj._id}`, objData)
      .subscribe((data: any) => {
        if (data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listProducts()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      })
  }

  public async delete(obj) {
    if(confirm('Você realmente deseja excluir este registro?')) {
      await this.api.deleteAPI(`products/${obj._id}`).subscribe((data: any) => {
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listProducts()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      }, (error: any) => {
        this.toastr.error(error.errors[0], 'Erro!')
      })
    }
  }

}
