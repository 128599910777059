import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { UsersComponent } from './pages/users/users.component';
import { ProductsComponent } from './pages/products/products.component';
import { ContentsComponent } from './pages/contents/contents.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { SubcategoriesComponent } from './pages/subcategories/subcategories.component';
import { SubsubcategoriesComponent } from './pages/subsubcategories/subsubcategories.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { LoginGuard } from './guards/login.guard';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'resetar-senha/:id', component: ResetPasswordComponent },
  { path: 'admins', component: AdminsComponent, canActivate: [LoginGuard] },
  { path: 'usuarios', component: UsersComponent, canActivate: [LoginGuard] },
  { path: 'produtos', component: ProductsComponent, canActivate: [LoginGuard] },
  { path: 'conteudos', component: ContentsComponent, canActivate: [LoginGuard] },
  { path: 'categorias', component: CategoriesComponent, canActivate: [LoginGuard] },
  { path: 'subcategorias', component: SubcategoriesComponent, canActivate: [LoginGuard] },
  { path: 'subsubcategorias', component: SubsubcategoriesComponent, canActivate: [LoginGuard] },
  { path: 'pedidos', component: RequestsComponent, canActivate: [LoginGuard] },
  { path: 'newsletter', component: NewsletterComponent, canActivate: [LoginGuard] },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
