<section class="page-login dark gradient">
    <h2>{{title}}</h2>
    <div class="box">
        <div [hidden]="redirect">
            <h3 class="text-center mb-15">Digite uma nova senha</h3>
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Senha</mat-label>
                            <input matInput type="password" formControlName="password" required>
                            <mat-icon matSuffix>lock</mat-icon>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Confirmar senha</mat-label>
                            <input matInput type="password" formControlName="confirmPassword" required>
                            <mat-icon matSuffix>lock</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button color="primary" class="btn float-right btn-block-xs" (click)="send()" [disabled]="isLoading">Alterar senha</button>
                    </div>
                </div>
            </form>
        </div>
        <div [hidden]="!redirect">
            <h3 class="text-center mb-15">Você será redirecionado para página de login em {{countdown}}</h3>
        </div>
        <hr>
        <div class="copyright">
            <p>Powered by </p><a href="http://codeart.com.br/" target="_blank"><img src="assets/img/codeart.png"></a>
        </div>
    </div>
</section>