import { Component, OnInit } from '@angular/core';
import { Admin } from './models/admin';
import { UtilsService } from './services/utils.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'forentec-dashboard';

  public admin: Admin = <Admin>{}
  public sidebarOpened: boolean
  public mobileScreen = $(window).width() < 992

  constructor(
    private util: UtilsService
  ) {
    this.mobileScreen ? this.sidebarOpened = false : this.sidebarOpened = true
    this.util.isSidebar().subscribe(data => {
      this.sidebarOpened = data
    })
  }

  ngOnInit(): void { }

  public cssClassValidator() {
    if(window.location.pathname.includes('login') || window.location.pathname.includes('resetar-senha')) {
      return false
    } else {
      return true
    }
  }
}
