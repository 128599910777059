<section class="page-login">
    <h2>{{title}}</h2>
    <div class="box">
        <h3 class="text-center mb-15">Faça seu login</h3>
        <form [formGroup]="formLogin">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input matInput type="email" formControlName="email" required>
                        <mat-icon matSuffix>account_circle</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Senha</mat-label>
                        <input matInput type="password" formControlName="password" required>
                        <mat-icon matSuffix>lock</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 order-md-2 mb-xs-15">
                    <button mat-raised-button color="primary" class="btn float-right btn-block-xs" (click)="login()" [disabled]="isLoading">Login</button>
                </div>
                <div class="col-12 col-md-6 order-md-1 d-flex align-items-end">
                    <a href="" (click)="modalResetPassword($event)">Esqueci a senha</a>
                </div>
            </div>
        </form>
        <hr>
        <div class="copyright">
            <p>Powered by </p><a href="https://codeart.com.br/" target="_blank"><img src="assets/img/codeart.png"></a>
        </div>
    </div>
</section>