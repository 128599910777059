import { Injectable, Output, EventEmitter } from '@angular/core';
import { faDizzy, faFlushed, faFrown, faSadCry } from '@fortawesome/free-regular-svg-icons';
import { environment } from 'src/environments/environment';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public faDizzy = faDizzy
  public faFlushed = faFlushed
  public faFrown = faFrown
  public faSadCry = faSadCry

  constructor() { }
  
  @Output() fireCollapse: EventEmitter<any> = new EventEmitter<any>()
  public collapseSidebar(value) {
    value ? this.fireCollapse.emit(false) : this.fireCollapse.emit(true)
  }
  public isSidebar = () => this.fireCollapse

  // localStorage
  public set = (key: string, obj) => window.localStorage.setItem(key, JSON.stringify(obj));
  public get = (key: string) => JSON.parse(window.localStorage.getItem(key));
  public del = (key: string) => window.localStorage.removeItem(key);


  // Random alert message
  public emojiMessage
  public textMessage
  public randomMessage() {
    const random = Math.floor(Math.random() * 4)
    
    switch(random) {
      case 0:
          this.emojiMessage = this.faDizzy
          this.textMessage = 'Todos os registros se foram'
        break;
      case 1:
          this.emojiMessage = this.faFlushed
          this.textMessage = 'Você adicionou algo?'
        break;
      case 2:
          this.emojiMessage = this.faFrown
          this.textMessage = 'Estou sozinho aqui'
        break;
      case 3:
          this.emojiMessage = this.faSadCry
          this.textMessage = 'Não encontrei nada'
        break;
      default:
        this.emojiMessage = this.faFrown
        this.textMessage = 'Estou sozinho aqui'
    }
  }

  public getImgUrl(img) {
    const newPath = img.split('dist/')[1]
    const finalString = `${environment.baseUrl}/${newPath}`
    return finalString
  }

  public capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  public translate(val: string) {
    switch(val) {
      case 'name':
        return 'Nome';
      case 'email':
        return 'E-mail';
      case 'phone':
        return 'Telefone';
      case 'cep':
        return 'CEP';
      case 'street':
        return 'Rua';
      case 'neighborhood':
        return 'Bairro';
      case 'city':
        return 'Cidade';
      case 'state':
        return 'Estado';
      case 'number':
        return 'Número';
      case 'complement':
        return 'Complemento';
      case 'password':
        return 'Senha';
      case 'confirmPassword':
        return 'Confirmar senha';
      case 'actualPassword':
        return 'Senha atual';
      case 'title':
        return 'Título';
      case 'section':
        return 'Seção';
      case 'content':
        return 'Conteúdo';
      case 'category':
      case 'idCategory':
        return 'Categoria';
      case 'subCategory':
      case 'idSubCategory':
        return 'Sub-categoria';
      case 'subSubCategory':
      case 'idSubSubCategory':
        return 'Sub-sub-categoria';
    }
  }

  public getFormValidationErrors(form) {
    const errors = []
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if(keyError === 'required') {
            errors.push({field: key, invalid: controlErrors[keyError]})
          }
        })
      }
    })
    return errors
  }
}
