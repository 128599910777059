import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-reset-password',
  templateUrl: './modal-reset-password.component.html',
  styleUrls: ['./modal-reset-password.component.scss']
})
export class ModalResetPasswordComponent implements OnInit {

  public email: string = ''
  public isLoading: boolean = false

  constructor(
    private dialogRef: MatDialogRef<ModalResetPasswordComponent>,
    private api: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }

  public async send() {
    this.isLoading = true
    await this.api.postOAPI('check-email', {email: this.email}).subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        this.toastr.success(data.msg, 'Sucesso!')
        this.email = ''
        this.close(true)
      }
    }, (err: any) => {
      this.isLoading = false
      this.toastr.error(err.error.errors[0], 'Erro')
    })
  }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

}
