import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-categories-add',
  templateUrl: './modal-categories-add.component.html',
  styleUrls: ['./modal-categories-add.component.scss']
})
export class ModalCategoriesAddComponent implements OnInit {
  formAdd: FormGroup

  public title: string = 'Adicionar'
  
  public isLoadingAction: boolean = false

  constructor(
    private dialogRef: MatDialogRef<ModalCategoriesAddComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private util: UtilsService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
  }

  private prepareForm() {
    this.formAdd = this.fb.group({
      title: this.fb.control('', [Validators.required])
    })
  }

  public async save() {
    if(!this.formAdd.valid) {
      const arr = this.util.getFormValidationErrors(this.formAdd)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.toastr.error(`Preencha todos os campos obrigatórios: ${newArr}`, 'Erro!')
    } else {
      this.isLoadingAction = true
      await this.api.postAPI('categories', this.formAdd.value).subscribe((data: any) => {
        this.isLoadingAction = false
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.close(true)
        }
      }, (err: any) => {
        this.isLoadingAction = false
        this.toastr.error(err.error.errors, 'Erro!')
      })
    }
  }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

}
