import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';
import { HttpClient } from '@angular/common/http';
import { NgxViacepService } from '@brunoc/ngx-viacep';

@Component({
  selector: 'app-modal-users-edit',
  templateUrl: './modal-users-edit.component.html',
  styleUrls: ['./modal-users-edit.component.scss']
})
export class ModalUsersEditComponent implements OnInit {
  formEdit: FormGroup

  public title: string = 'Editar'
  
  public isLoadingAction: boolean = false

  public states = []

  constructor(
    private dialogRef: MatDialogRef<ModalUsersEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private util: UtilsService,
    private http: HttpClient,
    private viacep: NgxViacepService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
  }

  private prepareForm() {
    this.formEdit = this.fb.group({
      name: this.fb.control(this.data.name, [Validators.required]),
      email: this.fb.control(this.data.email, [Validators.required, Validators.email]),
      phone: this.fb.control(this.data.phone),
      address: this.fb.group({
        cep: this.fb.control(this.data.address?.cep),
        street: this.fb.control(this.data.address?.street),
        number: this.fb.control(this.data.address?.number),
        complement: this.fb.control(this.data.address?.complement),
        neighborhood: this.fb.control(this.data.address?.neighborhood),
        city: this.fb.control(this.data.address?.city),
        state: this.fb.control(this.data.address?.state)
      }),
      active: this.fb.control(this.data.active)
    })
    this.getStates()
  }

  private async getStates() {
    await this.http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').subscribe((data: any) => {
      this.states = data
    })
  }

  public async getAddressByCep(cep) {
    await this.viacep.buscarPorCep(cep).then((data: any) => {
      if(data) {
        const address = {
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf
        }
        this.formEdit.patchValue({ address })
      }
     }).catch((err: any) => {
      console.log(err.message)
     })
  }

  public async save() {
    if(!this.formEdit.valid) {
      const arr = this.util.getFormValidationErrors(this.formEdit)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.toastr.error(`Preencha todos os campos obrigatórios: ${newArr}`, 'Erro!')
    } else {
      this.isLoadingAction = true
      await this.api.putAPI(`users/${this.data._id}`, this.formEdit.value).subscribe((data: any) => {
        this.isLoadingAction = false
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.close(true)
        }
      }, (err: any) => {
        this.isLoadingAction = false
        this.toastr.error(err.error.errors, 'Erro!')
      })
    }
  }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

}
