<section id="contents" class="content">
    <div class="container-fluid">
        <div class="page-header">
            <div class="title">
                {{title}}
                <div class="line end"></div>
            </div>
        </div>
        
        <div class="loader-container" [hidden]="!isLoading">
            <mat-spinner strokeWidth="6"></mat-spinner>
        </div>

        <div class="alert alert-info no-data" [hidden]="isLoading || isData">
            Nenhum registro foi encontrado
        </div>

        <div [hidden]="isLoading || !isData">
            <mat-form-field>
                <input matInput type="text" placeholder="Pesquisar" (keyup)="search($event.target.value)">
            </mat-form-field>
    
            <div class="table-responsive mat-elevation-z2">
                <table mat-table [dataSource]="subcategories" matSort matSortActive="title" matSortDirection="asc">

                    <!-- Title Column -->
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
                        <td mat-cell *matCellDef="let subcategory">{{subcategory.title}}</td>
                    </ng-container>
                    
                    <!-- Category Column -->
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
                        <!-- <td mat-cell *matCellDef="let subcategory">{{subcategory.category}}</td> -->
                        <td mat-cell *matCellDef="let subcategory">
                            <ng-container *ngFor="let category of categories | filter:{'_id':subcategory.idCategory}">
                                {{category.title}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- Created at Column -->
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Criado</th>
                        <td mat-cell *matCellDef="let subcategory">{{subcategory.createdAt | date:'dd/MM/yyyy'}}</td>
                    </ng-container>
                    
                    <!-- Active Column -->
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ativo</th>
                        <td mat-cell *matCellDef="let subcategory">
                            <mat-slide-toggle color="primary" [(ngModel)]="subcategory.active" (click)="activate(subcategory)"></mat-slide-toggle>
                        </td>
                    </ng-container>
    
                    <!-- Ações Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Ações</th>
                        <td mat-cell *matCellDef="let subcategory">
                            <button mat-flat-button color="primary" (click)="edit(subcategory)">Editar</button>
                            <button mat-flat-button color="warn" (click)="delete(subcategory)">Excluir</button>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    
    <button mat-fab color="accent" class="mat-btn-float" matTooltip="Adicionar" (click)="add()">
        <mat-icon>add</mat-icon>
    </button>
</section>