import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalSubcategoriesAddComponent } from 'src/app/templates/modal-subcategories-add/modal-subcategories-add.component';
import { ModalSubcategoriesEditComponent } from 'src/app/templates/modal-subcategories-edit/modal-subcategories-edit.component';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss']
})
export class SubcategoriesComponent implements OnInit {

  public title: string = 'Sub-categorias'

  public q = ''
  public subcategories: MatTableDataSource<any>
  public isLoading: boolean = false
  public isData: boolean = false
  public displayedColumns: string[] = ['title', 'category', 'createdAt', 'active', 'actions']

  public categories = []

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listSubCategories()
    this.getCategories()
  }

  private async listSubCategories() {
    this.isLoading = true
    await this.api.getOAPI('subcategories').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if (data.data.length) this.isData = true
        this.subcategories = new MatTableDataSource(data.data)
        this.subcategories.paginator = this.paginator
        this.subcategories.sort = this.sort
      }
    })
  }

  public search(q: string) {
    this.subcategories.filter = q.trim().toLowerCase()

    if (this.subcategories.paginator) {
      this.subcategories.paginator.firstPage()
    }
  }

  private getCategories() {
    this.api.getOAPI('categories').subscribe((data: any) => {
      this.categories = data.data
    })
  }

  public add() {
    const dialogRef = this.dialog.open(ModalSubcategoriesAddComponent, {
      width: '600px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listSubCategories()
      }
    })
  }

  public edit(obj) {
    const dialogRef = this.dialog.open(ModalSubcategoriesEditComponent, {
      width: '600px',
      maxWidth: '',
      panelClass: 'modal-form',
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(data => {
      if(data.success) {
        this.listSubCategories()
      }
    })
  }

  public async activate(obj) {
    let active = obj.active
    obj.active ? active = false : active = true
    
    const objData = {...obj, active}
    await this.api.putAPI(`subcategories/${obj._id}`, objData).subscribe((data: any) => {
      if(data.status) {
        this.toastr.success(data.msg, 'Sucesso!')
        this.listSubCategories()
      } else {
        this.toastr.error(data.msg, 'Erro!')
      }
    })
  }

  public async delete(obj) {
    if(confirm('Você realmente deseja excluir este registro?')) {
      await this.api.deleteAPI(`subcategories/${obj._id}`).subscribe((data: any) => {
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listSubCategories()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      }, (error: any) => {
        this.toastr.error(error.errors[0], 'Erro!')
      })
    }
  }

}
