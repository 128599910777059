import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-requests-details',
  templateUrl: './modal-requests-details.component.html',
  styleUrls: ['./modal-requests-details.component.scss']
})
export class ModalRequestsDetailsComponent implements OnInit {
  
  public title: string = ''

  constructor(
    private dialogRef: MatDialogRef<ModalRequestsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = `Pedido Nº ${this.data.cod}`
  }

  ngOnInit(): void { }

  public userAddress = (data) => `${data.address.street}, ${data.address.number} - ${data.address.complement} | ${data.address.neighborhood} - ${data.address.city} / ${data.address.state}`

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

}
