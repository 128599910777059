import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-subcategories-add',
  templateUrl: './modal-subcategories-add.component.html',
  styleUrls: ['./modal-subcategories-add.component.scss']
})
export class ModalSubcategoriesAddComponent implements OnInit {
  formAdd: FormGroup

  public title: string = 'Adicionar'
  
  public isLoadingAction: boolean = false

  public categories = []

  constructor(
    private dialogRef: MatDialogRef<ModalSubcategoriesAddComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private util: UtilsService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
    this.getCategories()
  }

  private prepareForm() {
    this.formAdd = this.fb.group({
      title: this.fb.control('', [Validators.required]),
      idCategory: this.fb.control('', [Validators.required]),
      category: this.fb.control('')
    })
  }

  public save() {
    if(!this.formAdd.valid) {
      const arr = this.util.getFormValidationErrors(this.formAdd)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.toastr.error(`Preencha todos os campos obrigatórios: ${newArr}`, 'Erro!')
    } else {
      this.isLoadingAction = true
      this.api.postAPI('subcategories', this.formAdd.value).subscribe((data: any) => {
        this.isLoadingAction = false;
        if (data.status) {
          this.toastr.success(data.msg, 'Sucesso!');
          this.close(true);
        }
      }, (err: any) => {
        this.isLoadingAction = false;
        this.toastr.error(err.error.errors, 'Erro!');
      })
    }
  }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

  public getCategories() {
    this.api.getOAPI('categories').subscribe((data: any) => {
      if(data.status) {
        this.categories = data.data
      }
    })
  }

  public getCategoryName() {
    const category = this.categories.filter(val => val._id === this.formAdd.value.idCategory)[0].title
    this.formAdd.patchValue({ category })
  }

}
