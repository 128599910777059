<div matDialogTitle>
    <h2>{{title}}</h2>
</div>
<mat-dialog-content (keydown.enter)="save()">
    <form [formGroup]="formEdit">
        <mat-form-field appearance="outline">
            <mat-label>Categoria</mat-label>
            <input matInput type="text" formControlName="title" placeholder="Título da categoria" required>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!formEdit.valid || isLoadingAction">Salvar</button>
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>