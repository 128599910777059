import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  public title: string = 'Newsletter'

  public faFileCsv = faFileCsv

  public q = ''
  public newsletter: MatTableDataSource<any>
  public isLoading: boolean = false
  public isData: boolean = false
  public displayedColumns: string[] = ['name', 'email', 'createdAt', 'actions']

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listNewsletter()
  }

  private async listNewsletter() {
    this.isLoading = true
    await this.api.getOAPI('newsletter').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if (data.data.length) this.isData = true
        this.newsletter = new MatTableDataSource(data.data)
        this.newsletter.paginator = this.paginator
        this.newsletter.sort = this.sort
      }
    })
  }

  public search(q: string) {
    this.newsletter.filter = q.trim().toLowerCase()

    if (this.newsletter.paginator) {
      this.newsletter.paginator.firstPage()
    }
  }

  public async delete(obj) {
    if(confirm('Você realmente deseja excluir este registro?')) {
      await this.api.deleteAPI(`newsletter/${obj._id}`).subscribe((data: any) => {
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.listNewsletter()
        } else {
          this.toastr.error(data.msg, 'Erro!')
        }
      }, (error: any) => {
        this.toastr.error(error.errors[0], 'Erro!')
      })
    }
  }

  public export() {
    this.api.getAPI('newsletter-export').subscribe((data: any) => {
      if(data.status) {
        const downloadUrl = `${environment.baseUrl}/${data.data.split('dist/')[1]}`
        const link = document.createElement('a')
        link.download = downloadUrl
        link.href = downloadUrl
        link.click()
      }
    })
  }

}
