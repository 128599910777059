import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-admins-edit',
  templateUrl: './modal-admins-edit.component.html',
  styleUrls: ['./modal-admins-edit.component.scss']
})
export class ModalAdminsEditComponent implements OnInit {
  formEdit: FormGroup

  public title: string = 'Editar'
  
  public isLoadingAction: boolean = false

  constructor(
    private dialogRef: MatDialogRef<ModalAdminsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private util: UtilsService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
  }

  private prepareForm() {
    this.formEdit = this.fb.group({
      email: this.fb.control(this.data.email, [Validators.required, Validators.email]),
      active: this.fb.control(this.data.active)
    })
  }

  public async save() {
    if(!this.formEdit.valid) {
      const arr = this.util.getFormValidationErrors(this.formEdit)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.toastr.error(`Preencha todos os campos obrigatórios: ${newArr}`, 'Erro!')
    } else {
      this.isLoadingAction = true
      await this.api.putAPI(`admins/${this.data._id}`, this.formEdit.value).subscribe((data: any) => {
        this.isLoadingAction = false
        if(data.status) {
          this.toastr.success(data.msg, 'Sucesso!')
          this.close(true)
        }
      }, (err: any) => {
        this.isLoadingAction = false
        this.toastr.error(err.error.errors, 'Erro!')
      })
    }
  }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

}
