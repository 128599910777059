<section id="contents" class="content">
    <div class="container-fluid">
        <div class="page-header">
            <div class="title">
                {{title}}
                <div class="line end"></div>
            </div>
        </div>

        <div class="loader-container" [hidden]="!isLoading">
            <mat-spinner strokeWidth="6"></mat-spinner>
        </div>

        <div class="alert alert-info no-data" [hidden]="isLoading || isData">
            Nenhum registro foi encontrado
        </div>

        <div [hidden]="isLoading || !isData">
            <mat-form-field>
                <input matInput type="text" placeholder="Pesquisar" (keyup)="search($event.target.value)">
            </mat-form-field>
    
            <div class="table-responsive mat-elevation-z2">
                <table mat-table [dataSource]="requests" matSort matSortActive="cod" matSortDirection="desc">

                    <!-- Cod Column -->
                    <ng-container matColumnDef="cod">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº Pedido</th>
                        <td mat-cell *matCellDef="let request">
                            <div class="overlay" *ngIf="request.finished"></div>
                            {{request.cod}}
                        </td>
                    </ng-container>
                    
                    <!-- User Column -->
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome cliente</th>
                        <td mat-cell *matCellDef="let request">
                            <div class="overlay" *ngIf="request.finished"></div>
                            {{request.client.name}}
                        </td>
                    </ng-container>
    
                    <!-- Created at Column -->
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Criado</th>
                        <td mat-cell *matCellDef="let request">
                            <div class="overlay" *ngIf="request.finished"></div>
                            {{request.createdAt | date:'dd/MM/yyyy'}} às {{request.createdAt | date:'HH:mm:ss'}}
                        </td>
                    </ng-container>
    
                    <!-- Details Column -->
                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef>Detalhes</th>
                        <td mat-cell *matCellDef="let request">
                            <button mat-flat-button (click)="openDetails(request)">Ver detalhes</button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="finished">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Finalizado</th>
                        <td mat-cell *matCellDef="let request">
                            <mat-checkbox color="primary" [(ngModel)]="request.finished" (click)="setFinished(request)"></mat-checkbox>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</section>