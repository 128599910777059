import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';
import { UploadService } from 'src/app/services/upload.service';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import * as ClassicEditor from 'src/ckeditor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-modal-products-add',
  templateUrl: './modal-products-add.component.html',
  styleUrls: ['./modal-products-add.component.scss']
})
export class ModalProductsAddComponent implements OnInit {
  formAdd: FormGroup

  public title: string = 'Adicionar'
  public faPlusSquare = faPlusSquare
  public faCheckCircle = faCheckCircle
  
  public isLoadingAction: boolean = false
  
  public categories = []
  public subcategories = []

  public Editor = ClassicEditor
  public EditorConfig = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote', 'undo', 'redo']
  }

  public visible = true
  public selectable = true
  public removable = true
  public addOnBlur = true
  readonly separatorKeysCodes: number[] = [ENTER, COMMA]
  public tagList = []

  constructor(
    private dialogRef: MatDialogRef<ModalProductsAddComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private util: UtilsService,
    public upload: UploadService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
    this.getCategories()
  }

  private prepareForm() {
    this.formAdd = this.fb.group({
      title: this.fb.control('', [Validators.required]),
      cod: this.fb.control('', [Validators.required]),
      idCategory: this.fb.control('', [Validators.required]),
      idSubCategory: this.fb.control('', [Validators.required]),
      img: this.fb.control(''),
      file: this.fb.control(''),
      description: this.fb.control(''),
      tags: this.fb.control('')
    })
    this.upload.previewPhotos = []
    this.upload.photos = []
    this.upload.previewFiles = []
    this.upload.files = []
  }

  public save() {
    if(!this.formAdd.valid) {
      const arr = this.util.getFormValidationErrors(this.formAdd)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.toastr.error(`Preencha todos os campos obrigatórios: ${newArr}`, 'Erro!')
    } else {
      this.isLoadingAction = true
      this.api.postAPI('products', this.formAdd.value)
        .subscribe((data: any) => {
          this.isLoadingAction = false
          if(data.status) {
            this.toastr.success(data.msg, 'Sucesso!')
            this.close(true)
          }
        }, (err: any) => {
          this.isLoadingAction = false
          this.toastr.error(err.error.errors, 'Erro!')
        })
    }
  }

  close(res = false): void {
    this.dialogRef.close({success: res})
  }

  public getCategories() {
    this.api.getOAPI('categories').subscribe((data: any) => {
      if(data.status) {
        this.categories = data.data
      }
    })
  }

  public getSubCategories(idCategory) {
    this.api.getOAPI(`subcategories/${idCategory}`).subscribe((data: any) => {
      this.subcategories = data.data
    })
  }

  public addChip(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value

    // Add our value
    if ((value || '').trim()) {
      this.tagList.push(value.trim())
      this.formAdd.patchValue({
        tags: this.tagList
      })
    }

    // Reset the input value
    if (input) {
      input.value = ''
    }
  }

  public removeChip(value): void {
    const index = this.tagList.indexOf(value)

    if (index >= 0) {
      this.tagList.splice(index, 1)
      this.formAdd.patchValue({
        tags: this.tagList
      })
    }
  }

}
