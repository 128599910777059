import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-contents-img',
  templateUrl: './modal-contents-img.component.html',
  styleUrls: ['./modal-contents-img.component.scss']
})
export class ModalContentsImgComponent implements OnInit {
  
  public title: string = ''

  constructor(
    private dialogRef: MatDialogRef<ModalContentsImgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public util: UtilsService
  ) {
    this.title = this.data.section
  }

  ngOnInit(): void { }

  close(res): void {
    this.dialogRef.close({success: res || false})
  }

}
