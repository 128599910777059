<section id="contents" class="content">
    <div class="container-fluid">
        <div class="page-header">
            <div class="title">
                {{title}}
                <div class="line end"></div>
            </div>
        </div>

        <div class="loader-container" [hidden]="!isLoading">
            <mat-spinner strokeWidth="6"></mat-spinner>
        </div>

        <div class="alert alert-info no-data" [hidden]="isLoading || isData">
            Nenhum registro foi encontrado
        </div>

        <div [hidden]="isLoading || !isData">
            <mat-form-field>
                <input matInput type="text" placeholder="Pesquisar" (keyup)="search($event.target.value)">
            </mat-form-field>
    
            <div class="table-responsive mat-elevation-z2">
                <table mat-table [dataSource]="users" matSort matSortActive="name" matSortDirection="asc">

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                        <td mat-cell *matCellDef="let user">{{user.name}}</td>
                    </ng-container>

                    <!-- E-mail Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
                        <td mat-cell *matCellDef="let user">{{user.email}}</td>
                    </ng-container>
                    
                    <!-- Phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefone</th>
                        <td mat-cell *matCellDef="let user">{{user.phone ? user.phone : 'Não informado'}}</td>
                    </ng-container>
                    
                    <!-- Address Column -->
                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Endereço</th>
                        <td mat-cell *matCellDef="let user" [matTooltip]="userAddress(user)" matTooltipPosition="above">
                            {{(userAddress(user).length > 40) ? (userAddress(user) | slice:0:40)+'...' : (userAddress(user))}}
                        </td>
                    </ng-container>
                    
                    <!-- Active Column -->
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ativo</th>
                        <td mat-cell *matCellDef="let user">
                            <mat-slide-toggle color="primary" [(ngModel)]="user.active" (click)="activate(user)"></mat-slide-toggle>
                        </td>
                    </ng-container>
    
                    <!-- Ações Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Ações</th>
                        <td mat-cell *matCellDef="let user">
                            <button mat-flat-button color="primary" (click)="edit(user)">Editar</button>
                            <button mat-flat-button color="warn" (click)="delete(user)">Excluir</button>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    
    <button mat-fab color="accent" class="mat-btn-float" matTooltip="Adicionar" (click)="add()">
        <mat-icon>add</mat-icon>
    </button>
</section>