import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  title = 'Forentec Dashboard'
  form: FormGroup

  public data: any = {}
  public isLoading: boolean = false
  public redirect: boolean = false
  public countdown = 5

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.route.params.subscribe(data => {
      this.data = data
    })
  }

  ngOnInit(): void {
    this.prepareForm()
  }

  private prepareForm() {
    this.form = this.fb.group({
      password: this.fb.control(''),
      confirmPassword: this.fb.control('')
    })
  }

  public async send() {
    this.isLoading = true
    await this.api.putOAPI(`reset-password/${this.data.id}`, this.form.value).subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        this.toastr.success(data.msg, 'Sucesso!')
        this.form.reset()
        this.redirect = true
        if(this.redirect) {
          let timeLeft = 5
          const timer = setInterval(() => {
            if(timeLeft === 0) {
              clearInterval(timer)
              this.router.navigate(['/login'])
            } else {
              this.countdown = timeLeft
            }
            timeLeft -= 1
          }, 1000)
        }
      }
    }, (err: any) => {
      this.isLoading = false
      this.toastr.error(err.error.errors[0], 'Erro')
    })
  }

}
