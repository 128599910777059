<div matDialogTitle>
    <h2>Esqueceu a senha?</h2>
</div>
<mat-dialog-content (keydown.enter)="send()">
    <p>Informe seu email, lhe enviaremos as instruções necessárias para recuperar sua senha</p>
    <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput type="email" [(ngModel)]="email" required>
        <mat-icon matSuffix>account_circle</mat-icon>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button color="primary" (click)="send()" [disabled]="!email">Enviar</button>
    <button mat-button (click)="close(false)">Fechar</button>
</mat-dialog-actions>