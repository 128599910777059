import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalRequestsDetailsComponent } from 'src/app/templates/modal-requests-details/modal-requests-details.component';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  public title: string = 'Pedidos'

  public q = ''
  public requests: MatTableDataSource<any>
  public isLoading: boolean = false
  public isData: boolean = false
  public displayedColumns: string[] = ['cod', 'user', 'createdAt', 'details', 'finished']

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listRequests()
  }

  private async listRequests() {
    this.isLoading = true
    await this.api.getAPI('requests').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if (data.data.length) this.isData = true
        this.requests = new MatTableDataSource(data.data)
        this.requests.paginator = this.paginator
        this.requests.sort = this.sort
      }
    })
  }

  public search(q: string) {
    this.requests.filter = q.trim().toLowerCase()

    if (this.requests.paginator) {
      this.requests.paginator.firstPage()
    }
  }

  public openDetails(obj) {
    this.dialog.open(ModalRequestsDetailsComponent, {
      width: '900px',
      maxWidth: '',
      panelClass: 'modal-form',
      data: obj
    })
  }

  public setFinished(obj) {
    let finished = obj.finished
    obj.finished ? finished = false : finished = true
    
    const objData = {...obj, finished}
    this.api.putAPI(`requests/${obj._id}`, objData).subscribe((data: any) => {
      if(data.status) {
        this.toastr.success(data.msg, 'Sucesso!')
        this.listRequests()
      } else {
        this.toastr.error(data.msg, 'Erro!')
      }
    })
  }

}
